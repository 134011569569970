import React from 'react';
import "./loader.css";

function Loader() {
  return (
    <div class="loadingio-spinner-rolling-tbkf2ce9si"><div class="ldio-w5kaspytpj">
        <div></div>
    </div></div>
  )
}

export default Loader